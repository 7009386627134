import React from 'react'
import { Image,Header} from 'semantic-ui-react'
class SearchBar extends React.Component{

    constructor(props) {
        super(props)
    
        this.state = {
             term:''
        }
    }
    handleChange=(e)=>{
        this.setState({
         term:  e.target.value
        })
    }

    onFormSubmit=(e)=>{
       e.preventDefault();
       this.props.onFormSubmit(this.state.term);
    }
    
    render(){
    
        return(
            <>
            <div className="container ui segment">
            <Header as='h2' color='black' textAlign='center'>
                    <Image src='/logo.png' /> 
                    Youtube video finder
              </Header>
            </div>
            <div className="search-bar ui segment">
                <form onSubmit={this.onFormSubmit} className="ui form">
                    <div className="field">
                        <label>Videos Type</label>
                        <input type="text" value={this.state.term} onChange={this.handleChange}></input>
                    </div>


                </form>
                  
            </div>
            </>
        )
    }
}

export default SearchBar