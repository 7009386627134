import axios from 'axios'
const KEY='AIzaSyA3WSf_JgpM274PG38zj4X69-dg2V5f9PQ';
export default axios.create({
    baseURL:'https://www.googleapis.com/youtube/v3/',
    params:{
        part:'snippet',
        maxResults:5,
        key:KEY
    }
})
